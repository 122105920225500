#logoHenry {
  margin-right: 20px;
}
.click {
  text-decoration: none;
  color: azure;
  font-size: 17px;
}
.nav{
  background: #323c41;
}
